import { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import Config from 'Config/Config';
import styled from 'styled-components';

import Pagination from 'Components/Pagination';
import PlayerBlock from 'Components/Players/Block';
import 'Assets/stylesheets/top/players.css';
import PlayerImage2X from 'Assets/images/top/player_image@2x.webp';
import PlayHead2X from 'Assets/images/top/play_head@2x.webp';
import PlayHeadSp from 'Assets/images/top/play_head_sp.webp';
import { PlayerList } from 'Schemes/Player';

const AbsoluteWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
`;

const Players = ({ featurePlayers }: { featurePlayers: PlayerList[] }) => {
  const [players, setPlayers] = useState([]);
  const [rookies, setRookies] = useState([]);
  const [playersTotalCount, setPlayersTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const playersTopRef = useRef<HTMLDivElement>(null);
  const playerTagRef = useRef<HTMLInputElement>(null);
  const playersPerPage = 16;

  const fetchPlayers = (page: number, limit: number) => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/players`, { params: { page: page, limit: limit } })
      .then((res) => {
        setPlayersTotalCount(res.data.TotalCount);
        setPlayers(res.data.Players);
      });
  };

  const handlePageChangePlayers = (page: number) => {
    if (playerTagRef.current) playerTagRef.current?.click();

    const nextPage = page + 1;
    setCurrentPage(nextPage);
    if (playersTopRef.current) {
      playersTopRef.current.scrollIntoView();
    }
  };

  const Bellow = () => {
    return (
      <div className="c-m-tabs__below">
        <figure className="p-m-index-player__image">
          <img src={PlayerImage2X} width="189" height="255" loading="lazy" alt="イメージ" />
        </figure>
        <p className="p-m-index-player__p02">
          あなただけの<span className="p-m-index-player__span">"推し"</span>
          <br />
          がここにいるかもしれないよっ！
        </p>
      </div>
    );
  };

  useEffect(() => {
    const fetchRookies = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/players/rookie`)
        .then((res) => {
          setRookies(res.data);
        });
    };

    fetchPlayers(currentPage, playersPerPage);
    fetchRookies();
  }, [currentPage]);

  return (
    <div id="players" className="p-m-index-player is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-player__top">
            <h2 className="p-m-index-player__head">
              <picture>
                <source media="(min-width: 751px)" width="695" height="140" srcSet={PlayHead2X} />
                <source media="(max-width: 750px)" width="562" height="112" srcSet={PlayHeadSp} />
                <img src={PlayHead2X} loading="lazy" alt="PLAYER" />
              </picture>
            </h2>
            <h3 className="p-m-index-player__h3">プレイヤー</h3>
            <p className="p-m-index-player__p01">
              声優経験者・Vtuber 経験者・配信経験者<span className="aux01">など個性豊かなプレイヤーが多数在籍！</span>
            </p>
          </div>
          <div className="p-m-index-player__bottom">
            <div ref={playersTopRef}></div>
            <div className="c-m-tabs">
              <input type="radio" name="tabs" id="tab_ctl01" defaultChecked={true} />
              <input type="radio" name="tabs" id="tab_ctl02" />
              <input type="radio" name="tabs" id="tab_ctl03" ref={playerTagRef} />
              <ul className="c-m-tabs__list">
                <li id="tab01" className="p-m-index-player__li">
                  <label htmlFor="tab_ctl01">
                    注目の
                    <br className="u-m-only-sp" />
                    プレイヤー
                  </label>
                </li>
                <li id="tab02" className="p-m-index-player__li">
                  <label htmlFor="tab_ctl02">
                    新人
                    <br className="u-m-only-sp" />
                    プレイヤー
                  </label>
                </li>
                <li id="tab03" className="p-m-index-player__li">
                  <label htmlFor="tab_ctl03">
                    すべての
                    <br className="u-m-only-sp" />
                    プレイヤー
                  </label>
                </li>
              </ul>
              <div id="tab_box01">
                <div className="c-m-tabs__page">
                  {featurePlayers.map((player: PlayerList) => (
                    <div key={`layer${player.UUID}`} className="p-m-index-player__player">
                      <PlayerBlock player={player} />
                    </div>
                  ))}
                  <div className="p-m-index-player__player is-m-dummy"></div>
                  <div className="p-m-index-player__player is-m-dummy"></div>
                </div>
              </div>
              <div id="tab_box02">
                <div className="c-m-tabs__page">
                  {rookies.map((player: PlayerList) => (
                    <div key={`layer${player.UUID}`} className="p-m-index-player__player">
                      <PlayerBlock player={player} />
                    </div>
                  ))}
                  <div className="p-m-index-player__player is-m-dummy"></div>
                  <div className="p-m-index-player__player is-m-dummy"></div>
                </div>
              </div>
              <div id="tab_box03">
                <div className="c-m-tabs__page">
                  {players.map((player: PlayerList) => (
                    <div key={`layer${player.UUID}`} className="p-m-index-player__player">
                      <PlayerBlock player={player} />
                    </div>
                  ))}
                  <div className="p-m-index-player__player is-m-dummy"></div>
                  <div className="p-m-index-player__player is-m-dummy"></div>
                </div>
              </div>
              <AbsoluteWrapper>
                <Pagination
                  totalCount={playersTotalCount}
                  perPage={playersPerPage}
                  handlePageChange={handlePageChangePlayers}
                />
                <Bellow />
              </AbsoluteWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Players;
