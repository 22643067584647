import "Assets/stylesheets/top/voice.css"
import VoiceHead2X from "Assets/images/top/voice_head@2x.webp";
import VoiceHeadSp from "Assets/images/top/voice_head_sp.webp";

import VoiceBalloon2X from "Assets/images/top/voice_balloon@2x.webp";
import VoiceBalloonSp from "Assets/images/top/voice_balloon_sp.webp";

import VoicePop012X from "Assets/images/top/voice_pop01@2x.webp";
import VoicePop022X from "Assets/images/top/voice_pop02@2x.webp";
import VoicePop032X from "Assets/images/top/voice_pop03@2x.webp";
import VoicePop01Sp from "Assets/images/top/voice_pop01_sp.webp";
import VoicePop02Sp from "Assets/images/top/voice_pop02_sp.webp";
import VoicePop03Sp from "Assets/images/top/voice_pop03_sp.webp";

import VoiceIllust2X from "Assets/images/top/voice_illust01@2x.webp";
import VoiceIllustSp from "Assets/images/top/voice_illust01_sp.webp";

import VoiceIcon012X from "Assets/images/top/voice_icon01@2x.webp";
import VoiceIcon022X from "Assets/images/top/voice_icon02@2x.webp";
import VoiceIcon032X from "Assets/images/top/voice_icon03@2x.webp";

import VoiceIcon01Sp from "Assets/images/top/voice_icon01_sp.webp";
import VoiceIcon02Sp from "Assets/images/top/voice_icon02_sp.webp";
import VoiceIcon03Sp from "Assets/images/top/voice_icon03_sp.webp";

const Voice = () => {
  return (
    <div className="p-m-index-voice is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-voice__top">
            <h2 className="p-m-index-voice__head">
              <picture>
                <source media="(min-width: 751px)" width="554" height="145"
                        srcSet={VoiceHead2X}/>
                  <source media="(max-width: 750px)" width="449" height="117"
                          srcSet={VoiceHeadSp}/>
                    <img src={VoiceHead2X} loading="lazy" alt="VOICE" />
              </picture>
            </h2>
            <h3 className="p-m-index-voice__h3">ボイスチャットで癒されよう！</h3>
          </div>
          <div className="p-m-index-voice__center">
            <h4 className="p-m-index-voice__h4">2PLAYには<strong>癒し系</strong>の声の<br/>
              プレイヤーが多数在籍！</h4>
            <figure className="p-m-index-voice__balloon">
              <picture>
                <source media="(min-width: 751px)" width="552" height="116"
                        srcSet={VoiceBalloon2X}/>
                  <source media="(max-width: 750px)" width="631" height="138"
                          srcSet={VoiceBalloonSp}/>
                    <img src={VoiceBalloon2X} loading="lazy" alt="吹き出し"/>
              </picture>
            </figure>
            <p className="p-m-index-voice__p01">元気が欲しいとき・・・<br/>
              悩みを誰かに聞いてほしいとき・・・<br/>
              誰かとおしゃべりしたいとき・・・<br/>
              まったりゆったり雑談したいとき・・・</p>
            <p className="p-m-index-voice__p02">ゲームしながら、たっぷりおしゃべりして<br/>
              楽しい時間が過ごせます。</p>
            <div className="u-m-container-row">
              <figure className="p-m-index-voice__pop01">
                <picture>
                  <source media="(min-width: 751px)" width="199" height="192"
                          srcSet={VoicePop012X}/>
                    <source media="(max-width: 750px)" width="204" height="165"
                            srcSet={VoicePop01Sp}/>
                      <img src={VoicePop012X} loading="lazy" alt="ゲーム"/>
                </picture>
              </figure>
              <figure className="p-m-index-voice__pop02">
                <picture>
                  <source media="(min-width: 751px)" width="199" height="192"
                          srcSet={VoicePop022X}/>
                    <source media="(max-width: 750px)" width="204" height="165"
                            srcSet={VoicePop02Sp}/>
                      <img src={VoicePop022X} loading="lazy" alt="雑談"/>
                </picture>
              </figure>
              <figure className="p-m-index-voice__pop03">
                <picture>
                  <source media="(min-width: 751px)" width="199" height="192"
                          srcSet={VoicePop032X}/>
                    <source media="(max-width: 750px)" width="204" height="165"
                            srcSet={VoicePop03Sp}/>
                      <img src={VoicePop032X} loading="lazy" alt="映画鑑賞"/>
                </picture>
              </figure>
            </div>
            <h5 className="p-m-index-voice__h5">
              <strong><span>⼆</span><span>⼈</span><span>だ</span><span>け</span>で</strong><br className="u-m-only-sp"/>素敵な時間を過ごしちゃおっ
            </h5>
            <figure className="p-m-index-voice__illust01">
              <picture>
                <source media="(min-width: 751px)" width="340" height="528"
                        srcSet={VoiceIllust2X}/>
                  <source media="(max-width: 750px)" width="340" height="528"
                          srcSet={VoiceIllustSp}/>
                    <img src={VoiceIllust2X} loading="lazy" alt="イラスト"/>
              </picture>
            </figure>
            <figure className="p-m-index-voice__icon03">
              <picture>
                <source media="(min-width: 751px)" width="184" height="184"
                        srcSet={VoiceIcon032X}/>
                  <source media="(max-width: 750px)" width="189" height="189"
                          srcSet={VoiceIcon03Sp}/>
                    <img src={VoiceIcon032X} loading="lazy" alt="アイコン"/>
              </picture>
            </figure>
            <figure className="p-m-index-voice__icon01">
              <picture>
                <source media="(min-width: 751px)" width="208" height="208"
                        srcSet={VoiceIcon012X}/>
                  <source media="(max-width: 750px)" width="215" height="215"
                          srcSet={VoiceIcon01Sp}/>
                    <img src={VoiceIcon012X} loading="lazy" alt="アイコン"/>
              </picture>
            </figure>
            <figure className="p-m-index-voice__icon02">
              <picture>
                <source media="(min-width: 751px)" width="221" height="221"
                        srcSet={VoiceIcon022X}/>
                  <source media="(max-width: 750px)" width="228" height="228"
                          srcSet={VoiceIcon02Sp}/>
                    <img src={VoiceIcon022X} loading="lazy" alt="アイコン"/>
              </picture>
            </figure>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Voice;