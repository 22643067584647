import { Link } from "react-router-dom";
import "Assets/stylesheets/top/mv.css";
import MvLogo2X from "Assets/images/top/mv_logo@2x.webp";
import MvLogoSp from "Assets/images/top/mv_logo_sp.webp";
import MvBalloon2X from "Assets/images/top/mv_balloon@2x.webp";
import MvBalloonSp from "Assets/images/top/mv_balloon_sp.webp";
import MvIllust2X from "Assets/images/top/mv_illust@2x.webp";
import MvIllustSp from "Assets/images/top/mv_illust_sp.webp";
import MvImage012X from "Assets/images/top/mv_image01@2x.webp";
import MvImage01Sp from "Assets/images/top/mv_image01_sp.webp";
import MvImage022X from "Assets/images/top/mv_image02@2x.webp";
import MvImage02Sp from "Assets/images/top/mv_image02_sp.webp";

const Mv = () => {
  return (
    <div className="p-m-index-mv">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-mv__top">
            <h2 className="p-m-index-mv__h2 u-m-deco--grad">ゲームフレンドマッチングサービス</h2>
            <h3 className="p-m-index-mv__h3">
              ふたりっきり<small>で</small><br/><strong>ゲーム</strong>楽しもっ！
            </h3>
            <figure className="p-m-index-mv__logo">
              <picture>
                <source
                  media="(min-width: 751px)"
                  width="261"
                  height="68"
                  srcSet={MvLogo2X}
                />
                <source
                  media="(max-width: 750px)"
                  width="193"
                  height="51"
                  srcSet={MvLogoSp}
                />
                <img src={MvLogo2X} alt="2PLAYのロゴ" />
              </picture>
            </figure>
            <p className="p-m-index-mv__p01">30分実質無料!</p>
            <figure className="p-m-index-mv__balloon">
              <picture>
                <source
                  media="(min-width: 751px)"
                  width="285"
                  height="44"
                  srcSet={MvBalloon2X}
                />
                <source
                  media="(max-width: 750px)"
                  width="392"
                  height="59"
                  srcSet={MvBalloonSp}
                />
                <img src={MvBalloon2X} alt="吹き出し" />
              </picture>
            </figure>
            <p className="p-m-index-mv__p02">amazonギフト券<br className="u-m-only-pc"/><span
              className="aux01">プレゼントキャンペーン</span></p>
            <Link to="/sign_up" className="p-m-index-mv__button u-m-button--gradation">
              まずはLINE登録
            </Link>
            <small style={{
              fontSize: "1rem",
              fontWeight: "bold",
              marginTop: "1rem"
            }}>※キャンペーン適用には条件があります</small>
            <figure className="p-m-index-mv__illust">
              <picture>
                <source
                  media="(min-width: 751px)"
                  width="634"
                  height="748"
                  srcSet={MvIllust2X}
                />
                <source
                  media="(max-width: 750px)"
                  width="572"
                  height="672"
                  srcSet={MvIllustSp}
                />
                <img src={MvIllust2X} alt="イラスト" />
              </picture>
            </figure>
            <figure className="p-m-index-mv__image01">
              <picture>
                <source
                  media="(min-width: 751px)"
                  width="74"
                  height="47"
                  srcSet={MvImage012X}
                />
                <source
                  media="(max-width: 750px)"
                  width="85"
                  height="55"
                  srcSet={MvImage01Sp}
                />
                <img src={MvImage012X} alt="コントローラー" />
              </picture>
            </figure>
            <figure className="p-m-index-mv__image02">
              <picture>
                <source
                  media="(min-width: 751px)"
                  width="56"
                  height="64"
                  srcSet={MvImage022X}
                />
                <source
                  media="(max-width: 750px)"
                  width="65"
                  height="75"
                  srcSet={MvImage02Sp}
                />
                <img src={MvImage022X} alt="ヘッドセット" />
              </picture>
            </figure>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mv;
