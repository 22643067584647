import { Game } from 'Schemes/Game';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGhost } from '@fortawesome/free-solid-svg-icons';
import Colors from 'Utils/Colors';
import { BaseButton } from 'Components/Parts/Buttons';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FC } from 'react';

type Props = {
  game: Game | null;
} & RouteComponentProps;

const Wrapper = styled.section`
  width: 100%;
  height: 100px;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${Colors.bgWhite};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Left = styled.div`
  display: table;
`;

const Right = styled.div``;

const GameIcon = styled.img`
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  margin: 0;
`;

const GameTitle = styled.p`
  height: 60px;
  vertical-align: middle;
  display: table-cell;
  width: 140px;
  margin: 0px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  padding-left: 20px;
`;

const NoGameIcon = styled.div`
  border-radius: 10px;
  background-color: #dddddd;
  width: 60px;
  height: 60px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;

const StyledGhost = styled(FontAwesomeIcon)`
  color: ${Colors.white};
  height: 60px;
  font-size: 30px;
`;

const StyledBaseButton = styled(BaseButton)`
  height: 36px;
  margin: 12px 0;
  float: right;
`;

const GameSearchHeader: FC<Props> = ({ game, history }) => {
  let content;
  if (game === null) {
    content = (
      <>
        <Left>
          <NoGameIcon>
            <StyledGhost icon={faGhost} />
          </NoGameIcon>
          <GameTitle>ゲームが選択されていません</GameTitle>
        </Left>
        <Right>
          <StyledBaseButton value="変更する" onClick={() => history.push('/games')}></StyledBaseButton>
        </Right>
      </>
    );
  } else {
    content = (
      <>
        <Left>
          <GameIcon src={game.IconURL} />
          <GameTitle>{game.Name}</GameTitle>
        </Left>
        <Right>
          <StyledBaseButton value="変更する" onClick={() => history.push('/games')}></StyledBaseButton>
        </Right>
      </>
    );
  }

  return <Wrapper>{content}</Wrapper>;
};

export default withRouter(GameSearchHeader);
