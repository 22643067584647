import { FC } from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import MenuTitle from './Parts/MenuTitle';
import { SimpleUl } from './Parts/SimpleUl';

const HowToWrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: ${Colors.white};
`;

const HowTo: FC = () => {
  return (
    <>
      <MenuTitle>ご利用ガイド</MenuTitle>
      <HowToWrapper>
        <SimpleUl>
          <li>
            ▶<a href="/#flow">ゲームプレイの流れ</a>
          </li>
          <li>
            ▶<a href="https://tayori.com/faq/599bff17b7888757941e59ebc432a081a3add2ce">よくある質問</a>
          </li>
          <li>
            ▶<a href="https://media.2play.game/lp/player">プレイヤーになる</a>
          </li>
          <li>
            ▶
            <a href="https://media.2play.game/about" target="_blank" rel="noopener noreferrer">
              運営会社
            </a>
          </li>
          <li>
            ▶
            <a href="https://media.2play.game" target="_blank" rel="noreferrer">
              2PLAY media
            </a>
          </li>
        </SimpleUl>
      </HowToWrapper>
    </>
  );
};

export default HowTo;
