import 'Assets/stylesheets/top/addition.css';
import AdditionHead2X from 'Assets/images/top/addition_head@2x.webp';
import AdditionHeadSp from 'Assets/images/top/addition_head_sp.webp';
import AdditionIcon012X from 'Assets/images/top/addition_icon01@2x.webp';
import AdditionIcon01Sp from 'Assets/images/top/addition_icon01_sp.webp';
import AdditionIcon022X from 'Assets/images/top/addition_icon02@2x.webp';
import AdditionIcon02Sp from 'Assets/images/top/addition_icon02_sp.webp';
import AdditionIcon032X from 'Assets/images/top/addition_icon03@2x.webp';
import AdditionIcon03Sp from 'Assets/images/top/addition_icon03_sp.webp';

const Addition = () => {
  return (
    <div className="p-m-index-addition is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-addition__top">
            <h2 className="p-m-index-addition__head">
              <picture>
                <source media="(min-width: 751px)" width="906" height="145" srcSet={AdditionHead2X} />
                <source media="(max-width: 750px)" width="733" height="117" srcSet={AdditionHeadSp} />
                <img src={AdditionHead2X} loading="lazy" alt="ADDITION" />
              </picture>
            </h2>
            <h3 className="p-m-index-addition__h3">ゲームで遊ぶだけじゃない！</h3>
          </div>
          <div className="p-m-index-addition__bottom">
            <div className="u-m-container--shadow">
              <figure className="p-m-index-addition__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={AdditionIcon012X} />
                  <source media="(max-width: 750px)" width="236" height="236" srcSet={AdditionIcon01Sp} />
                  <img src={AdditionIcon012X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <h4 className="p-m-index-addition__h4">⼀緒に遊んでゲームの技術を上げる</h4>
              <p className="p-m-index-addition__p01">
                ただ⼀緒に遊ぶだけじゃない！
                ゲームに詳しいプレイヤーもたくさんいるので⼀緒に遊びながら、ゲームの技術を上げていきましょう。
              </p>
            </div>
            <div className="u-m-container--shadow">
              <figure className="p-m-index-addition__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={AdditionIcon022X} />
                  <source media="(max-width: 750px)" width="233" height="234" srcSet={AdditionIcon02Sp} />
                  <img src={AdditionIcon022X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <h4 className="p-m-index-addition__h4">
                ゲームのコーチングも
                <br className="u-m-only-pc" />
                可能！
              </h4>
              <p className="p-m-index-addition__p01">
                ベテランゲーマーも多数在籍！
                初⼼者の⽅にも丁寧に遊び⽅を教えます。ゲームの技術を⾼めたい⽅はコーチングできるプレイヤーを選んで。
              </p>
            </div>
            <div className="u-m-container--shadow">
              <figure className="p-m-index-addition__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={AdditionIcon032X} />
                  <source media="(max-width: 750px)" width="234" height="234" srcSet={AdditionIcon03Sp} />
                  <img src={AdditionIcon032X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <h4 className="p-m-index-addition__h4">
                雑談だけでも
                <br className="u-m-only-pc" />
                OK！
              </h4>
              <p className="p-m-index-addition__p01">
                ゆったりまったり、雑談だけでもOK。癒しボイスのプレイヤーや、悩み相談が得意なプレイヤーがいます。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addition;
