import 'Assets/stylesheets/top/footer.css';
import FooterLogo2X from 'Assets/images/top/footer_logo@2x.webp';
import FooterLogoSp from 'Assets/images/top/footer_logo_sp.webp';

const Footer = () => {
  return (
    <footer className="l-m-footer">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="l-m-footer__top">
            <h2 className="l-m-footer__logo">
              <picture>
                <source media="(min-width: 751px)" width="93" height="24" srcSet={FooterLogo2X} />
                <source media="(max-width: 750px)" width="270" height="71" srcSet={FooterLogoSp} />
                <img src={FooterLogo2X} loading="lazy" alt="2PLAY" />
              </picture>
            </h2>
            <nav>
              <ul className="l-m-footer__list">
                <li className="l-m-footer__li">
                  <a href="https://tayori.com/faq/599bff17b7888757941e59ebc432a081a3add2ce">よくある質問</a>
                </li>
                <li className="l-m-footer__li">
                  <a href="https://media.2play.game/lp/player">プレイヤーになる</a>
                </li>
                <li className="l-m-footer__li">
                  <a href="https://media.2play.game">2PLAY media</a>
                </li>
              </ul>
              <ul className="l-m-footer__list">
                <li className="l-m-footer__li">
                  <a href="https://media.2play.game/2playterms">利用規約</a>
                </li>
                <li className="l-m-footer__li">
                  <a href="https://media.2play.game/privacy-policy/#2play">プライバシーポリシー</a>
                </li>
                <li className="l-m-footer__li">
                  <a href="https://tayori.com/form/49cdc98fa647969dc59ede63c0df10a26a311c54">お問い合わせ</a>
                </li>
                <li className="l-m-footer__li">
                  <a href="https://media.2play.game/about/" target="_blank" rel="noopener noreferrer">
                    運営会社
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="l-m-footer__bottom">
            <small className="l-m-footer__copyright">(c) Copyright 2PLAY. All Rights Reserved</small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
