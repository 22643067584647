import 'Assets/stylesheets/top/flow.css';
import FlowHead2X from 'Assets/images/top/flow_head@2x.webp';
import FlowHeadSp from 'Assets/images/top/flow_head_sp.webp';

import FlowIcon012X from 'Assets/images/top/flow_icon01@2x.webp';
import FlowIcon022X from 'Assets/images/top/flow_icon02@2x.webp';
import FlowIcon032X from 'Assets/images/top/flow_icon03@2x.webp';
import FlowIcon042X from 'Assets/images/top/flow_icon04@2x.webp';
import FlowIcon052X from 'Assets/images/top/flow_icon05@2x.webp';
import FlowIcon062X from 'Assets/images/top/flow_icon06@2x.webp';

import FlowIcon01Sp from 'Assets/images/top/flow_icon01_sp.webp';
import FlowIcon02Sp from 'Assets/images/top/flow_icon02_sp.webp';
import FlowIcon03Sp from 'Assets/images/top/flow_icon03_sp.webp';
import FlowIcon04Sp from 'Assets/images/top/flow_icon04_sp.webp';
import FlowIcon05Sp from 'Assets/images/top/flow_icon05_sp.webp';
import FlowIcon06Sp from 'Assets/images/top/flow_icon06_sp.webp';

const Flow = () => {
  return (
    <div id="flow" className="p-m-index-flow is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-flow__top">
            <h2 className="p-m-index-flow__head">
              <picture>
                <source media="(min-width: 751px)" width="513" height="145" srcSet={FlowHead2X} />
                <source media="(max-width: 750px)" width="416" height="117" srcSet={FlowHeadSp} />
                <img src={FlowHead2X} loading="lazy" alt="FLOW" />
              </picture>
            </h2>
            <h3 className="p-m-index-flow__h3">ご利⽤の流れ</h3>
            <p className="p-m-index-flow__p01">
              初⼼者でもわかりやすい<span className="aux01">簡単6ステップ！</span>
            </p>
          </div>
          <div className="p-m-index-flow__bottom">
            <div className="c-m-card--flow p-m-index-flow__card">
              <figure className="p-m-index-flow__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={FlowIcon012X} />
                  <source media="(max-width: 750px)" width="204" height="203" srcSet={FlowIcon01Sp} />
                  <img src={FlowIcon012X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <span className="p-m-index-flow__span u-m-back--user">購入者（ユーザー）</span>
              <h4 className="p-m-index-flow__h4">「チャット」をする</h4>
              <p className="p-m-index-flow__p02">
                一緒にゲームをしたいプレイヤーが見つかったら、まずはチャットを立ち上げましょう。
              </p>
            </div>
            <div className="c-m-card--flow p-m-index-flow__card">
              <figure className="p-m-index-flow__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={FlowIcon022X} />
                  <source media="(max-width: 750px)" width="204" height="203" srcSet={FlowIcon02Sp} />
                  <img src={FlowIcon022X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <span className="p-m-index-flow__span u-m-back--player">プレイヤー</span>
              <h4 className="p-m-index-flow__h4">一緒にゲームができる時間を伝える</h4>
              <p className="p-m-index-flow__p02">
                プレイヤーは「これから1時間大丈夫です」などの会話をしていただき、チケットの購入をお願いしてください。
              </p>
            </div>
            <div className="c-m-card--flow p-m-index-flow__card">
              <figure className="p-m-index-flow__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={FlowIcon032X} />
                  <source media="(max-width: 750px)" width="204" height="203" srcSet={FlowIcon03Sp} />
                  <img src={FlowIcon032X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <span className="p-m-index-flow__span u-m-back--user">購入者（ユーザー）</span>
              <h4 className="p-m-index-flow__h4">チケットを購入する</h4>
              <p className="p-m-index-flow__p02">
                プレイヤーとゲームをする時間が決まったら、一緒に遊びたいゲームを選択し、30分単位でチケットを購入しましょう。（チケットの有効期限は5日です。）
              </p>
            </div>
            <div className="c-m-card--flow p-m-index-flow__card">
              <figure className="p-m-index-flow__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={FlowIcon042X} />
                  <source media="(max-width: 750px)" width="204" height="203" srcSet={FlowIcon04Sp} />
                  <img src={FlowIcon042X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <span className="p-m-index-flow__span u-m-back--player">プレイヤー</span>
              <h4 className="p-m-index-flow__h4">ゲームIDやDiscordIDを交換する</h4>
              <p className="p-m-index-flow__p02">プレイヤーはゲーム開始する準備を行ってください。</p>
            </div>
            <div className="c-m-card--flow p-m-index-flow__card">
              <figure className="p-m-index-flow__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={FlowIcon052X} />
                  <source media="(max-width: 750px)" width="204" height="203" srcSet={FlowIcon05Sp} />
                  <img src={FlowIcon052X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <span className="p-m-index-flow__span u-m-back--user">購入者（ユーザー）</span>
              <h4 className="p-m-index-flow__h4">「プレイ開始」を押す</h4>
              <p className="p-m-index-flow__p02">
                プレイヤーとつながることができたら、「プレイ開始」を押すことで時間のカウントが開始されます。
              </p>
            </div>
            <div className="c-m-card--flow p-m-index-flow__card">
              <figure className="p-m-index-flow__icon">
                <picture>
                  <source media="(min-width: 751px)" width="135" height="134" srcSet={FlowIcon062X} />
                  <source media="(max-width: 750px)" width="204" height="203" srcSet={FlowIcon06Sp} />
                  <img src={FlowIcon062X} loading="lazy" alt="アイコン" />
                </picture>
              </figure>
              <span className="p-m-index-flow__span u-m-back--user">購入者（ユーザー）</span>
              <h4 className="p-m-index-flow__h4">プレイヤーを評価</h4>
              <p className="p-m-index-flow__p02">
                プレイが終わったら、プレイヤーを評価しましょう。
                <span className="aux01">
                  ※ プレイ終了後、つながったIDなどは、プレイヤーから削除をしていただきます。
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flow;
