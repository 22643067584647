import "Assets/stylesheets/top/about.css";
import AboutHead2X from "Assets/images/top/about_head@2x.webp";
import AboutHeadSp from "Assets/images/top/about_head_sp.webp";
import AboutImage2X from "Assets/images/top/about_image@2x.webp";
import AboutImageSp from "Assets/images/top/about_image_sp.webp";

const About = () => {
  return (
    <div className="p-m-index-about">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-about__top">
            <h2 className="p-m-index-about__head">
              <picture>
                <source media="(min-width: 751px)" width="652" height="145"
                        srcSet={AboutHead2X} />
                  <source media="(max-width: 750px)" width="527" height="117"
                          srcSet={AboutHeadSp} />
                    <img src={AboutHead2X} loading="lazy" alt="ABOUT" />
              </picture>
            </h2>
            <h3 className="p-m-index-about__h3">『⼀緒にわいわいやると、<br className="u-m-only-sp" />もっとたのしいよねっ！』</h3>
          </div>
          <div className="p-m-index-about__bottom">
            <figure className="p-m-index-about__image">
              <picture>
                <source media="(min-width: 751px)" width="505" height="405"
                        srcSet={AboutImage2X} />
                  <source media="(max-width: 750px)" width="577" height="463"
                          srcSet={AboutImageSp} />
                    <img src={AboutImage2X} loading="lazy" alt="イメージ" />
              </picture>
            </figure>
            <p className="p-m-index-about__p01">⼀⼈で黙々と遊ぶのも楽しいけど<span
              className="aux01">⼆⼈で⼀緒にわいわい遊ぶゲームはもっとたのしい</span>
              2PLAY公式プレイヤーと⼀緒に<span className="aux02">ボイスチャットでおしゃべりしながら</span>⼆⼈で⼀緒にゲームを楽しもう！</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;